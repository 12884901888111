import React, { useContext} from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { injectIntl } from 'react-intl';
import Wrapper from './../../components/wrapper';
import Button from './../../components/buttons';
import BlendImage from './../../components/blendImage';
import { LanguageContext } from './../../containers/layouts';
import { shuffle } from '../../utils';

import style from './interestCarrousel.module.scss';

const InterestCarrousel = ({ intl, highlights }) => {
    const data = useStaticQuery(graphql`
        query AllCases {
          allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "case-page"}}}) {
            edges {
              node {
                id
                frontmatter {
                  title
                  industry
                  listimage {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 280) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  metadata {
                    excerpt
                  }
                  expertiseTags
                }
                fields {
                  slug
                  langKey
                }
              }
            }
          }
        }
    `);

    const { langKey, location } = useContext(LanguageContext);
    const filteredCasesOnLanguage = shuffle(data.allMarkdownRemark.edges.filter((item) => item.node.fields.langKey === langKey && item.node.fields.slug !== location.pathname));
    const highlightedCases = filteredCasesOnLanguage.filter((caseItem) => {
        if (highlights) {
            const match = highlights.some((tag) => {
                if (caseItem.node.frontmatter.expertiseTags) {
                    return caseItem.node.frontmatter.expertiseTags.some((r) => r === tag);
                }
                return false;
            });

            if (match) {
                return caseItem;
            }
        }

        return null;
    });

    const notHighlightedCases = filteredCasesOnLanguage.filter((caseItem) => {
       if (!highlightedCases.includes(caseItem)) {
           return caseItem;
       }
       return null;
    });

    // combine filtered and not filtered array to have minimal 4 items.
    let cases = shuffle(highlightedCases).slice(0,3);
    if (cases.length < 4) {
        cases = cases.concat(shuffle(notHighlightedCases).slice(0, (4 - cases.length)));
    }

    return (
        <Wrapper>
            <div className={style.carrousel}>
                <h3 className={style.title}>{intl.formatMessage({id: 'interestTitle'})}</h3>
                <div className={style.carrouselContainer}>
                    <div className={[style.carrouselInner, style[`cards${cases.length}`]].join(' ')}>
                    {cases.map((item) => {
                        const { frontmatter, fields } = item.node;
                        return (
                            <div key={item.node.id} className={style.carrouselCard}>
                                <Link to={fields.slug}>
                                    <div className={style.carrouselCardInner}>
                                        { (frontmatter && frontmatter.listimage) &&
                                        <div className={style.thumbnail}>
                                            <BlendImage
                                                src={(frontmatter && frontmatter.listimage) ? frontmatter.listimage.childImageSharp.fluid : null}
                                                fluid alt="people"
                                                className={style.blendImage}/>
                                        </div>
                                        }
                                        <h3 className={style.cardTitle}>{frontmatter.title}</h3>
                                        <span className={style.cardMeta}>{frontmatter.industry}</span>
                                        {frontmatter.metadata.excerpt.substring(0, 100)}...
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                    </div>
                </div>
                {cases.length > 3 &&
                <Button type="GatbsyLink" primary to={`/${(langKey === 'en') ? 'en/' : ''}cases`} className={style.buttonMore}>{intl.formatMessage({id: 'showAllCases'})}</Button>
                }
            </div>
        </Wrapper>
    );
};

export default injectIntl(InterestCarrousel);
