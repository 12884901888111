import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './../../components/wrapper/';
import WrapperContent from './../../components/wrapperContent/';
import style from './articleHeader.module.scss';

const ArticleHeader = ({ title, titleMeta, subTitle, text }) => (
    <header className={style.articleHeader}>
        <Wrapper>
            <WrapperContent>
                <h1 className={style.title}>{title}</h1>
                {titleMeta &&
                <span className={style.meta}>{titleMeta}</span>
                }
                {subTitle &&
                <h3 className={style.heading}>{subTitle}</h3>
                }
                {text &&
                <p>{text}</p>
                }
            </WrapperContent>
        </Wrapper>
    </header>
);

ArticleHeader.propTypes = {
    title: PropTypes.string,
    titleMeta: PropTypes.string,
    subTitle: PropTypes.string,
    text: PropTypes.string,
};

export default ArticleHeader;
