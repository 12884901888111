import React from 'react';
import { graphql } from 'gatsby';
import Content from './../components/content/';
import { H3 } from './../components/headings/';
import Wrapper from './../components/wrapper/';
import FullImage from './../components/fullImage/';
import WrapperContent from './../components/wrapperContent/';
import Tags from './../components/tags';
import QuickContact from './../components/quickContact';
import SocialBar from './../components/socialBar';
import ArticleHeader from './../containers/articleHeader';
import InterestCarrousel from './../containers/interestCarrousel';
import {LanguageLayout} from '../containers/layouts';

export const CaseDetailTemplate = ({ frontmatter, fullImage, solutionText, resultsText, quickContact, tags, slug}) => (
    <article>
        <ArticleHeader
            title={frontmatter.title}
            titleMeta={frontmatter.industry}
            subTitle={frontmatter.challengeTitle}
            text={frontmatter.challengeText}
        />
        {fullImage &&
        <Wrapper fullWidth={true}>
            <FullImage src={fullImage} />
        </Wrapper>
        }
        <Wrapper>
            <WrapperContent>
                { (frontmatter.solutionTitle && solutionText) &&
                    <>
                        <H3>{frontmatter.solutionTitle}</H3>
                        <Content html={solutionText} />
                    </>
                }
                { (frontmatter.resultsTitle && resultsText) &&
                <>
                    <H3>{frontmatter.resultsTitle}</H3>
                    <Content html={resultsText} />
                </>
                }
                <SocialBar slug={slug} messageID="socialBar.case.socialMessage" pageTitle={frontmatter.title} noBorder email />
                <Tags tagList={tags} />
            </WrapperContent>
        </Wrapper>
        {quickContact &&
        <Wrapper>
            <WrapperContent component color="blue">
                <QuickContact
                    title={quickContact.quickContactTitle}
                    text={quickContact.quickContactText}
                    phone={quickContact.quickContactPhone}
                    email={quickContact.quickContactEmail}
                    image={quickContact.quickContactImage}
                />
            </WrapperContent>
        </Wrapper>
        }
        <InterestCarrousel highlights={(tags) ? tags.map((tag) => tag.name) : []}/>
    </article>
);

const CasesDetails = (props) => {
    if (props.data.page.edges.length > 0) {
        let quickContact = null;
        let tags = [];
        const { frontmatter, fields } = props.data.page.edges[0].node;
        const fullImage = (frontmatter && frontmatter.fullImage) ? frontmatter.fullImage.childImageSharp.fluid : null;
        const expertiseTags = props.data.expertises.edges;
        const { quickContacts } = props.data;

        if (quickContacts && quickContacts.group ){
            quickContacts.group.map(({nodes}) => {
                const result = nodes.filter((gnodes) => gnodes.frontmatter.quickContactName === frontmatter.quickContact);
                if (result.length > 0) {
                    quickContact = result[0].frontmatter;
                }
                return nodes;
            });
        }

        // Tags
        if (frontmatter.expertiseTags) {
            frontmatter.expertiseTags.map((tag) => {
                // lookup tag
                const data = expertiseTags.filter(obj => {
                    return obj.node.frontmatter.id === tag;
                });
                if (data.length > 0) {
                    tags.push({
                        name: data[0].node.frontmatter.title || tag,
                        slug: data[0].node.fields.slug,
                        langKey: data[0].node.fields.langKey,
                    });
                }
                return null;
            });
        }

        // Add industry tags without slug to tag array
        if (frontmatter.industryTags) {
            tags = tags.concat(frontmatter.industryTags.map((tag) => {
                return {
                    name: tag,
                }
            }));
        }

        return (
            <LanguageLayout location={props.location}>
                <CaseDetailTemplate
                    frontmatter={frontmatter}
                    solutionText={(fields.frontmattermd && fields.frontmattermd.solutionText) ? fields.frontmattermd.solutionText.html : null}
                    resultsText={(fields.frontmattermd && fields.frontmattermd.resultsText) ? fields.frontmattermd.resultsText.html : null}
                    fullImage={fullImage}
                    quickContact={quickContact}
                    tags={tags}
                    slug={fields.slug}
                />
            </LanguageLayout>
        );
    }

    return <div>Page not found</div>;

};

export default CasesDetails;

export const caseDetailQuery = graphql`
query CaseBySlug($langKey: String!, $slug:String!) {
  page: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "case-page"}}, fields: {slug: {eq: $slug}}}) {
    edges {
      node {
        id
        frontmatter {
          title
          industry
          quickContact
          challengeTitle
          challengeText
          fullImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          solutionTitle
          resultsTitle
          expertiseTags
          industryTags
        }
        fields {
          slug
          frontmattermd {
            solutionText {
              html
            }
            resultsText {
              html
            }
          }
        }
      }
    }
  }
  quickContacts: allMarkdownRemark {
    group(field: frontmatter___quickContactName) {
      nodes {
        frontmatter {
          quickContactEmail
          quickContactPhone
          quickContactText
          quickContactTitle
          quickContactName
          quickContactImage {
            publicURL
            childImageSharp {
              fixed(width: 128, height: 128) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      }
    }
  }
  expertises: allMarkdownRemark(filter:{frontmatter:{ templateKey:{ eq:"expertise-page"}}, fields: {langKey: { eq: $langKey}}}) {
    edges {
      node {
        frontmatter {
            id
            title
        }
        fields {
          slug
          langKey
        }
      }
    }
  }
}
`;
