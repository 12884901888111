import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';
import Tag from './tag';
import style from './tags.module.scss';

const Tags = ({ intl, tagList }) => (
    <>
        <h4 className={style.tagsTitle}>Tags</h4>
        {tagList && tagList.length > 0 &&
        <ul className={style.tags}>
            {tagList.map((tagObj, index) => (
                <Tag key={`tags${index}`}>
                    {tagObj.slug &&
                        <Link to={tagObj.slug}>{tagObj.name}</Link>
                    }
                    {!tagObj.slug &&
                        <span>{tagObj.name}</span>
                    }
                </Tag>
            ))}
        </ul>
        }
        {(!tagList || tagList.length === 0) &&
        <span className={style.noTags}>{ intl.formatMessage({id: 'noTags'}) }</span>
        }
    </>
);

Tags.propTypes = {
    tagList: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        langKey: PropTypes.string,
    })),
    intl: PropTypes.object,
};

export default injectIntl(Tags);
