import React from 'react';
import PropTypes from 'prop-types';
import style from './tag.module.scss';

const Tag = ({ children, ...rest }) => (
    <li className={style.tag} {...rest} >{children}</li>
);

Tag.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Tag;
