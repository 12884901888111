import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import style from './socialBar.module.scss';

import { ReactComponent as Linkedin } from './../../images/social/linkedin.inline.svg';
import { ReactComponent as Twitter } from './../../images/social/twitter.inline.svg';
import { ReactComponent as Whatsapp } from './../../images/social/whatsapp-bw.svg';
import { ReactComponent as Email } from './../../images/social/email.svg';

const SocialBar = ({ intl, slug, messageID, pageTitle, noBorder, className, email }) => {
    const { site } = useStaticQuery(
        graphql`query SITE_QUERY {
        site {
          siteMetadata {
            siteUrl: url
          }
        }
      }`);

    const share_url = site.siteMetadata.siteUrl + slug;
    const escapedMessage = escape(intl.formatMessage({id: (messageID) ? messageID : 'socialBar.default.socialMessage'}, { url: share_url }));

    return (
        <div className={classNames(
            style.socialBar,
            (noBorder) ? style.noBorder : null,
            className
        )}>
            <ul className={style.socialBarList}>
                <li>
                    <a
                        id="socialMediaShareWhatsapp"
                        href={`https://api.whatsapp.com/send?phone=&text=${escapedMessage}&source=&data=`}
                        // onClick={() => handleSocialClick("whatsapp")}
                        title={intl.formatMessage({id: 'socialBar.whatsapp.label'})}
                        className={style.coloredIcon}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Whatsapp width="100%" height="100%" viewBox="0 0 24 24" />
                    </a>
                </li>
                <li>
                    <a
                        id="socialMediaShareLinkedIn"
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${share_url}&title=${encodeURIComponent(pageTitle)}&source=${encodeURIComponent(pageTitle)}`}
                        title={intl.formatMessage({id: 'socialBar.linkedin.label'})}
                        className={style.coloredIcon}
                        target="_blank"
                        rel="noopener noreferrer"
                        // onClick={() => handleSocialClick("linkedIn")}
                    >
                        <Linkedin width="100%" height="100%" viewBox="0 0 24 24" />
                    </a>
                </li>
                <li>
                    <a
                        id={"socialMediaShareTwitter"}
                        href={`http://twitter.com/share?text=${encodeURIComponent(pageTitle)}&url=${share_url}`} title={intl.formatMessage({id: 'socialBar.twitter.label'})}
                        className={style.coloredIcon}
                        target="_blank"
                        rel="noopener noreferrer"
                        // onClick={() => handleSocialClick("twitter")}
                     >
                        <Twitter width="100%" height="100%" viewBox="0 0 24 24" />
                    </a>
                </li>
                {email &&
                <li>
                    <a
                       id="socialMediaShareMail"
                       href={`mailto:?subject=${pageTitle}&body=${escapedMessage}`}
                       title={intl.formatMessage({id: 'socialBar.email.label'})}
                       className={style.coloredIcon}
                       target="_blank"
                       rel="noopener noreferrer"
                       // onClick={() =>handleSocialClick("mail")}
                    >
                        <Email width="100%" height="100%" viewBox="0 0 24 24"/>
                    </a>
                </li>
                }
            </ul>
        </div>
    );
};

const withIntl = injectIntl(SocialBar);
export default withIntl;
