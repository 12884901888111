import React from 'react';
import PropTypes from 'prop-types';
import illustration from './../../images/imageIllustration.svg';
import style from './fullImage.module.scss';
import Img from 'gatsby-image';

const FullImage = ({ src }) => (
    <div className={style.fullImage}>
        <div className={style.illustrationContainer}>
            <div className={style.illustrationContainerInner}>
                <img src={illustration} className={style.illustration} alt="illustration" />
                <Img fluid={src} className={style.image} fadeIn backgroundColor={true} alt="full size" />
            </div>
        </div>
    </div>
);

FullImage.propTypes = {
    src: PropTypes.object.isRequired,
};

export default FullImage;
