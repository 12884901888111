import React from 'react';
import { injectIntl } from 'react-intl';
import Button from './../buttons';
import BlendImage from './../blendImage';
import { ReactComponent as Phone } from './../../images/Icons/phone.svg';

import style from './quickContact.module.scss';

const QuickContact = ({ intl, title, text, phone, email, image }) => (
    <div className={style.quickContact}>
        <div className={style.body}>
            {image &&
            <BlendImage src={image.childImageSharp.fixed} fixed alt="avatar" className={style.bodyAvatar} width={128} height={128} />
            }
            <h3 className={style.bodyTitle}>{title}</h3>
            <p className={style.bodyText} dangerouslySetInnerHTML={{ __html: text}} />
        </div>
        {(email || phone) &&
        <div className={style.actions}>
            {email &&
            <Button
                primary
                to={`mailto:${email}`}
                type="AHREF"
                className={style.button}>
                    {intl.formatMessage({id: 'sendMessage'})}
            </Button>
            }
            {phone &&
            <Button
                icon={<Phone/>}
                secondary
                to={`tel:${phone}`}
                type="AHREF"
                className={style.button}>
                    {intl.formatMessage({id: 'call'})} {phone}
            </Button>
            }
        </div>
        }
    </div>
);

export default injectIntl(QuickContact);
